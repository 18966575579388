.partners-section {
  position: relative;
  font-family: var(--body-font);
  line-height: 1.21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  font-weight: var(--font-weight-normal);
  width: 100%;
  overflow-x: hidden;
  color: var(--text-dark);
  background-color: #faf9f6;
  margin: 0;
}

.partners-section h2 {
  font-size: 3rem;
  color: #333;
}

.partner-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.partner-card a {
  color: var(--textColor);
}
.partner-card-size-lg,
.partner-card-size-md,
.partner-card-size-sm,
.partner-card-size-xl,
.partner-card-size-xs {
  border-style: none;
}
.partner-card-link,
.partner-card-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.partner-card-media {
  position: relative;
  display: flex;
  justify-content: center;
  height: 11em;
}
.partner-card-media img {
  max-width: 70%;
  height: auto;
}
.partner-card-size-xs img {
  max-width: 50%;
}
.partner-card-size-sm img {
  max-width: 55%;
}
.partner-card-size-md img {
  max-width: 60%;
}
.partner-card-size-lg img {
  max-width: 65%;
}
.partner-card-size-xl img {
  max-width: 70%;
}
.partner-card-text {
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;
}
.partners-container {
  position: relative;
  box-sizing: border-box;
  max-width: var(--breakpoint-xl);
  padding: var(--block-vertical-padding-md) var(--block-horisontal-padding-sm);
  margin-top: -6em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10em;
  display: grid;
  justify-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  color: var(--textColor);
  grid-template-areas: "intro" "partners-list";
}
.partners-intro {
  grid-area: intro;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 95ch;
  text-align: center;
}

.partners-intro h3 {
  font-size: 1.4rem !important;
  color: #333;
  margin-bottom: 10px;
}

.partners-intro p {
  font-size: 1.6rem;
  color: #555;
  margin: 2em 0;
  margin-top: 1em;
  text-align: center;
  line-height: 1.6;
}

.partner-card-text p {
  font-size: 1.4rem;
}

.partners-circle {
  position: absolute;
  top: 20rem;
  right: -25rem;
  transform: scale(1);
  transform-origin: center;
  opacity: 0.4;
  transition: transform 0.3s ease;
}

.partners-circle:hover {
  transform: scale(1.1);
}

.partners-list {
  grid-area: partners-list;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 700px) {
  .partners-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 900px) {
  .partners-container {
    padding-left: var(--block-horisontal-padding-lg);
    padding-right: var(--block-horisontal-padding-lg);
  }
  .partners-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1100px) {
  .partners-container {
    grid-auto-columns: 1fr;
  }
}
@media only screen and (min-width: 1500px) {
  .partners-container {
    padding-left: var(--theme-horizontal-padding, 0);
    padding-right: var(--theme-horizontal-padding, 0);
  }
}

@media (max-width: 768px) {
  .partners-container {
    margin-top: 0em;
    margin-bottom: 0em;
  }
}
