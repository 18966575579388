.footer-container {
  background-color: #1a1a1a;
  color: #f1f1f1;
  padding: 1.5rem;
  font-family: Arial, sans-serif;
  border-top: #525252 solid;
  border-width: 0.1vh;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  margin: 0 2rem;
  min-width: 200px;
}

.footer-column-left {
  text-align: left;
}

.footer-column-right {
  text-align: right;
}

.footer-column-center {
  text-align: center;
}

.footer-column p {
  font-family: Barlow, sans-serif;
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.footer-column p:hover {
  color: #b74438;
}

.footer-column a:hover {
  color: #b74438;
}

.footer-column h4 {
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: #b74438;
}

.footer-column a {
  color: #f1f1f1;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin: 0.5rem 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
}

.footer-bottom p {
  font-size: 0.9rem;
}

.footer-bottom-line {
  border-width: 0.4rem;
  border-bottom-style: solid;
  border-image: linear-gradient(90deg, #b74438, #c99c97, #b74438);
  border-image-slice: 1;
}

.footer-logo {
  display: flex;
  justify-self: center;
  align-items: center;
  margin-top: 2.5em;
  width: 15em;
}

.linkedin img {
  padding-top: 1em;
  width: 3em;
}

/* Responsiv design */
@media (max-width: 1024px) {
  .footer-container {
    padding: 0rem;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    justify-self: center;
    flex-wrap: nowrap;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0rem;
  }
  .footer-bottom {
    padding-bottom: 2rem;
    padding-top: 0rem;
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    margin: 0 1rem;
  }

  .footer-column-left {
    text-align: center;
  }

  .footer-column-right {
    text-align: center;
  }

  .footer-column-center {
    text-align: center;
  }
  .footer-column h4 {
    font-size: 1.6rem;
  }
  .footer-column p {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .footer-container {
    padding: 2rem 1rem;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    margin: 0 0.5rem;
  }

  .footer-column-right {
    text-align: center;
  }

  .footer-column-center {
    text-align: center;
  }
  .footer-column h4 {
    font-size: 1.6rem;
  }
  .footer-column p {
    font-size: 1.3rem;
  }
}
