.about-textblock {
  padding: 4rem;
  z-index: 2;
  position: relative;
  grid-area: text;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  overflow: hidden;
  flex: 1 1;
  grid-template-columns: 1.15fr 1fr;
  grid-template-areas: "text media";
  text-align: left;
}

@media only screen and (min-width: 1500px) {
  .about-textblock {
    overflow: visible;
  }
}

.tech-image {
  position: absolute;
  top: 100%; /* Centrera vertikalt */
  left: 50%; /* Centrera horisontellt */
  transform: translate(
    -50%,
    -50%
  ); /* Flytta tillbaka med 50% av bildens storlek */
  z-index: -1; /* Placera bakom annat innehåll */
  width: 100%;
  max-width: 25rem; /* Anpassa bredden */
  opacity: 0.1; /* Sätt genomskinlighet */
  padding-top: 35rem;
}

.TextMediaBlock_media-left {
  grid-template-areas: "media text";
  grid-template-columns: 1fr 1.05fr;
}

.TextMediaBlock_media-right {
  grid-template-areas: "text media";
  grid-template-columns: 1.05fr 1fr;
}

.TextMediaBlock_content {
  display: grid;
  align-items: center;
  gap: 2rem;
}

.TextMediaBlock_content .TextMediaBlock_media {
  padding: 0 !important;
}

.map {
  justify-self: center;
  height: 85vh;
  width: 90%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(31, 33, 41, 0.5);
  position: relative;
}

.map button {
  background-color: rgb(112, 112, 112) !important;
  font-size: 1rem !important;
}

.esri-area-measurement-3d__hint-text {
  font-size: 1.2rem;
}
.esri-direct-line-measurement-3d__hint-text {
  font-size: 1.2rem;
}

/* clean-widget */
.clean-widget {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  /* padding: 0.6rem; */
  width: 2.1rem;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  box-shadow: inset;
}

/* Toggle-knapp för clean */
.clean-widget-toggle {
  /* padding-bottom: 0.1rem; */
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clean-widget-icon:hover {
  transform: scale(1.1);
}

/* Filter-widget */
.filter-widget {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  /* padding: 0.6rem; */
  width: 2.1rem;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  box-shadow: inset;
}

/* Toggle-knapp för filter */
.filter-widget-toggle {
  /* padding-bottom: 0.1rem; */
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
  color: #8b8b8b;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s ease-in-out;
}

.filter-widget-toggle:hover {
  background-color: #f0f0f0;
}

.filter-widget-icon:hover {
  color: rgb(87, 87, 87) !important;
}

/* Filter-sektion */
.filter-widget-filters {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  bottom: inherit;
  right: 0;
  position: absolute;
}

/* Standardstil för filter-alternativ */
.filter-widget-content {
  padding: 0.4rem;
  width: 7rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  background-color: rgba(31, 33, 41, 0.8);
  color: white;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.4);
}

.filter-widget-content:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Aktivt filter */
.filter-widget-content.active {
  background-color: #2ecc71;
  color: white;
  border-color: #27ae60;
}

/* Platslista-widget */
.locations-widget {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
  padding: 0.7rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  width: 8rem;
  z-index: 1000;
  background-color: rgba(31, 33, 41, 0.7);
}

/* Titel för Platslista */
.locations-widget-title {
  margin: 0 0 0 0;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  color: white !important;
}

.locations-widget-title:hover {
  color: rgb(117, 172, 175) !important;
}

/* Platslista */
.locations-widget-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 0.6rem;
}

/* Standardstil för locations-widgets */
.locations-widget-content {
  padding: 0.4rem 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  font-family: var(--body-font);
  line-height: 1.21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
  font-weight: var(--font-weight-normal);
  background-color: transparent;
  color: white;
}

/* Hover-effekt */
.locations-widget-content:hover,
.filter-widget-content:hover {
  color: #8b8b8b;
}

/* Aktiv knapp-effekt */
.locations-widget-content.active,
.filter-widget-content.active {
  color: #588a8d;
}

.about-container {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  font-family: var(--body-font);
  line-height: 1.21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  font-weight: var(--font-weight-normal);
  width: 100%;
  /* overflow-x: hidden; */
  color: var(--text-dark);
  background-color: #faf9f6;
}

.TextMediaBlock_content .TextMediaBlock_text {
  padding: var(--grid-gutter-lg) var(--grid-gutter);
  z-index: var(--z-content);
  position: relative;
  grid-area: text;
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor);
  overflow: hidden;
}
.TextMediaBlock_content .TextMediaBlock_text > * {
  flex: 1 1;
}

@media (max-width: 1024px) {
  .TextMediaBlock_content .TextMediaBlock_text {
    padding: 0;
    padding-right: 0.5rem;
  }
  .TextMediaBlock_content {
    display: block !important;
  }
}

/* Anpassning för mobiler och mindre skärmar */
@media only screen and (max-width: 700px) {
  .about-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .TextMediaBlock_content {
    grid-template-areas:
      "text"
      "media";
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .about-textblock {
    padding: 1rem;
  }

  .map {
    width: 85%;
    margin-top: 1rem;
  }

  .tech-image {
    position: absolute;
    top: 100%; /* Centrera vertikalt */
    left: 50%; /* Centrera horisontellt */
    transform: translate(
      -50%,
      -50%
    ); /* Flytta tillbaka med 50% av bildens storlek */
    z-index: -1; /* Placera bakom annat innehåll */
    width: 70%;
    max-width: 25rem; /* Anpassa bredden */
    opacity: 0.1; /* Sätt genomskinlighet */
    padding-top: 35rem;
  }
}

/* Anpassning för större skärmar */
@media only screen and (min-width: 1000px) {
  .about-container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .TextMediaBlock_content {
    grid-template-columns: 1fr 1fr;
  }

  .TextMediaBlock_media-left {
    grid-template-areas: "media text";
    grid-template-columns: 1fr 1.05fr;
  }

  .TextMediaBlock_media-right {
    grid-template-areas: "text media";
    grid-template-columns: 1.05fr 1fr;
  }
}

@media only screen and (min-width: 1000px) {
  .TextMediaBlock_media-left .TextMediaBlock_text {
    text-align: right;
  }
}
@media only screen and (min-width: 1500px) {
  .TextMediaBlock_content .TextMediaBlock_text {
    overflow: visible;
  }
}
