/* Global styles for the page */
.businesses-page {
  font-family: Arial, sans-serif;
  color: #333;
  position: relative;
  overflow-x: hidden;
}

/* Hero section */
.businesses-hero {
  position: relative;
  padding: 50px 20px;
  text-align: center;
  /* background: linear-gradient(135deg, #f7f7f7, #eaeaea); */
  overflow: hidden;
  z-index: 0;
}

.circle-background {
  position: absolute;
  top: 15.6em;
  scale: 1.6;
  right: 70px;
  z-index: -1;
  opacity: 0.1;
}

.circle-background img {
  width: 500px;
  height: auto;
  z-index: -1;
}

.businesses-hero-content {
  position: relative;
  z-index: 1;
}

.businesses-hero-content h1 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #333;
}

.businesses-hero-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #555;
}

/* Cards section */
.businesses-cards {
  padding: 15vh 20px 10vh;
  padding-bottom: 20vh;
}

.businesses-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
}

.businesses-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 22em;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.businesses-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  background-color: #eaeaea;
  cursor: pointer;
}

.businesses-card-image {
  background-color: #f5f5f5;
  padding: 2.5em;
  border-bottom: 2px solid #e0e0e0;
}

.businesses-card-image img {
  width: 50px;
  height: 50px;
}

.businesses-card-content {
  padding: 20px;
}

.businesses-card-content h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.businesses-card-content p {
  font-size: 1rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .businesses-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .businesses-card {
    width: 90%;
  }
}
