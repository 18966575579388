.business-section {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 8em;
}

.business-section h2 {
  font-size: 3rem;
  margin-bottom: 2em;
  color: #333;
}

.business-cards-wrapper {
  display: flex;
  justify-content: center;
  gap: 3em;
  padding: 0 20px;
  flex-wrap: wrap;
}

.business-card {
  position: relative;
  width: 20em;
  height: 24em;
  perspective: 1000px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.business-card .card-front,
.business-card .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* border-radius: 8px; */
}

.business-card .card-front {
  z-index: 2;
}

.business-card .card-back {
  transform: rotateY(180deg);
  text-align: center;
}

.business-card.flipped .card-front {
  transform: rotateY(-180deg);
}

.business-card.flipped .card-back {
  transform: rotateY(0);
}

.business-card:hover {
  transform: scale(1.05);
  background-color: #e0e0e0;
}

.business-card:hover .card-front {
  background-color: #e0e0e0;
}

.business-card-content {
  padding: 1rem;
  margin-top: 15px;
}

.business-card-content h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.business-card-content p {
  font-size: 1rem;
  color: #555;
  margin: 2em 0;
  margin-top: 2em;
  text-align: center;
  line-height: 1.6;
}

.business-card-content ul {
  margin: 1em 0;
  margin-left: 1em;
  margin-top: 3em;
  padding-left: 2em;
  text-align: left;
  list-style-type: disc;
}

.business-card-content ul li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.business-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-card-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  /* border-radius: 8px 8px 0 0; */
}

.business-card-arrow {
  font-size: 1.8rem;
  color: #588a8d;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.7s ease-in-out;
}

.business-card-arrow:hover {
  transform: translateX(10px);
}

/* @media (max-width: 1200px) {
  .business-card {
    width: 15em;
  }
  .business-cards-wrapper {
    gap: 2em;
  }
} */

@media only screen and (min-width: 1080) and (max-width: 1450px) {
  .business-cards-wrapper {
    gap: 1.5em;
  }
}

@media (max-width: 1024px) {
  .business-section {
    text-align: center;
    margin-top: 8em;
    margin-bottom: 10em;
  }
  .business-card-content h3 {
    font-size: 1.8rem;
  }

  .business-card-content p {
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0em 0;
    margin-top: 1em;
  }

  .business-section h2 {
    font-size: 2.5rem;
    margin-bottom: 5rem;
    color: #333;
  }
}

@media (max-width: 768px) {
  .business-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .business-card {
    width: 100%;
  }

  .business-card-content {
    padding: 1rem;
  }

  .business-card-content h3 {
    font-size: 2rem;
  }

  .business-card-content p {
    font-size: 1.3rem;
    color: #555;
    margin: 2em 0;
    margin-top: 1em;
    text-align: center;
    line-height: 1.6;
  }
}

@media (max-width: 480px) {
  .business-card {
    width: 90%;
  }

  .business-card-content h3 {
    font-size: 1.4rem;
  }

  .business-card-content p {
    font-size: 0.9rem;
  }
}
