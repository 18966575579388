html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Barlow;
}

/* .bg-light {
  background-color: #fff;
}

.bg-dark {
  background-color: #faf9f6;
  color: #fff;
  min-height: 100vh;
  padding-top: 100px;
} */

/* .theme-white {
  --backgroundColor: #fff;
  --backgroundColor-textMedia: #160c72;
  --headingColor: #000;
  --textColor: #000;
  --textColor-muted: #858585;
  --buttonBackgroundColor: #8d442d;
  --buttonBackgroundColorDarker: #8d442d;
  --buttonTextColor: #fff;
  --dividerColor: #838383;
  --cardBorderColor: #dadada;
  --cardBackgroundColor: #f8f8f8;
  --theme-horizontal-padding: 0;
  --promoTableHighlight: #e3e3e3;
} */
/*
.theme-rust {
  --backgroundColor: #8d442d;
  --backgroundColor-textMedia: #d7cf8c;
  --headingColor: #fff;
  --textColor: #fff;
  --textColor-muted: #c9c9c9;
  --buttonBackgroundColor: #fff;
  --buttonBackgroundColorDarker: #ebebeb;
  --buttonTextColor: #000;
  --dividerColor: #fff;
  --cardBorderColor: #a35942;
  --cardBackgroundColor: #a64f35;
  --theme-horizontal-padding: var(--block-horisontal-padding-lg);
  --promoTableHighlight: #e3e3e3;
}
.theme-lightGrey {
  --backgroundColor: #e3e3e3;
  --backgroundColor-textMedia: rgb(9, 23, 48);
  --headingColor: #000;
  --textColor: #000;
  --textColor-muted: #727272;
  --buttonBackgroundColor: #8d442d;
  --buttonBackgroundColorDarker: #7a3520;
  --buttonTextColor: #fff;
  --dividerColor: #fff;
  --cardBorderColor: #fff;
  --cardBackgroundColor: #f2f2f2;
  --theme-horizontal-padding: var(--block-horisontal-padding-lg);
  --promoTableHighlight: #fff;
}
.theme-darkGrey {
  --backgroundColor: #4e4e4e;
  --backgroundColor-textMedia: #d7cf8c;
  --headingColor: #fff;
  --textColor: #fff;
  --textColor-muted: #8b8b8b;
  --buttonBackgroundColor: #8ab3b6;
  --buttonBackgroundColorDarker: #6f989b;
  --buttonTextColor: #000;
  --dividerColor: #616161;
  --cardBorderColor: #8b8b8b;
  --cardBackgroundColor: #707070;
  --theme-horizontal-padding: var(--block-horisontal-padding-lg);
  --promoTableHighlight: #444;
}
.theme-yellow {
  --backgroundColor: #d7cf8c;
  --backgroundColor-textMedia: #e3e3e3;
  --headingColor: #000;
  --textColor: #000;
  --textColor-muted: #727272;
  --buttonBackgroundColor: #8d442d;
  --buttonBackgroundColorDarker: #7a3520;
  --buttonTextColor: #fff;
  --dividerColor: #fff;
  --cardBorderColor: #dfddc7;
  --cardBackgroundColor: #e0dbb0;
  --theme-horizontal-padding: var(--block-horisontal-padding-lg);
  --promoTableHighlight: #e3e3e3;
}


*/
:root {
  --white: #fff;
  --black: #000;
  --backgroundColor: #fff;
  --headingColor: #000;
  --textColor: #000;
  --buttonBackgroundColor: #8d442d;
  --buttonBackgroundColorDarker: #7a3520;
  --buttonTextColor: #fff;
  --theme: #51caa2;
  --theme-rgb: 81, 202, 162;
  --theme-darker: #278365;
  --theme-contrast: #e8f8f1;
  --theme-light-gray: #e3e3e3;
  --theme-muted-text: #1c545a;
  --theme-muted-background: #e5e5e5;
  --theme-alt: orange;
  --theme-alt-darker: #996400;
  --theme-alt-contrast: #fff;
  --heading-dark: #003a40;
  --text-dark: #15393f;
  --background-dark: #003a40;
  --background-dark-lighter: #00434a;
  --border-dark: rgba(0, 0, 0, 0.08);
  --heading-light: #fff;
  --text-light: #fff;
  --background-light: #f6fcf9;
  --border-light: hsla(0, 0%, 100%, 0.1);
  --selected-nav-item: #51caa2;
  --header-height: 80px;
  --header-height-mobile: 55px;
  --logo-padding: 12px;
  --logo-wrapper-height: 60px;
  --navigation-padding: 25px;
  --pre-top-height: 30px;
  --top-combo-calc: calc(var(--header-height) + var(--pre-top-height));
  --breakpoint-xs: 320px;
  --breakpoint-sm: 480px;
  --breakpoint-md: 900px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1500px;
  --block-vertical-padding-sm: 4rem;
  --block-vertical-padding-md: 8rem;
  --block-vertical-padding-lg: 12rem;
  --block-vertical-padding-sm-mobile: 4rem;
  --block-vertical-padding-md-mobile: 7rem;
  --block-vertical-padding-lg-mobile: 11rem;
  --block-horisontal-padding-sm: 20px;
  --block-horisontal-padding-md: 30px;
  --block-horisontal-padding-lg: 70px;
  --block-intro-spacing: 0 0 5rem 0;
  --block-container-padding: 15px;
  --block-content-text-padding-sm: 4rem 0 7rem 0;
  --block-content-text-padding-md: 6rem;
  --block-content-text-padding-lg: 9rem;
  --block-vertical-padding-sm-minus: -5rem;
  --block-vertical-padding-md-minus: -10rem;
  --block-vertical-padding-lg-minus: -15rem;
  --block-vertical-padding-sm-minus-minus: -3rem;
  --block-vertical-padding-md-minus-minus: -6rem;
  --block-vertical-padding-lg-minus-minus: -9rem;
  --block-horisontal-padding-sm-minus: -15px;
  --block-horisontal-padding-md-minus: -30px;
  --block-horisontal-padding-lg-minus: -70px;
  --block-horisontal-maxwidth-sm-minus: 760px;
  --block-horisontal-maxwidth-md-minus: 1060px;
  --block-horisontal-maxwidth-lg-minus: 1360px;
  --content-vertical-padding-sm: 1rem;
  --content-vertical-padding-md: 2rem;
  --content-vertical-padding-lg: 3rem;
  --z-background: 0;
  --z-content: 2;
  --z-header: 3;
  --grid-width-small: 850px;
  --grid-width: 1200px;
  --grid-width-big: 1400px;
  --grid-gutter: 1.5rem;
  --grid-gutter-md: calc(var(--grid-gutter) * 2);
  --grid-gutter-lg: calc(var(--grid-gutter) * 3);
  --button-border-radius: 0px;
  --body-font: Barlow;
  --heading-font: Barlow;
  --button-font: Arial;
  --font-size: 1rem;
  --font-size-big: 1.4rem;
  --font-weight-normal: normal;
  --font-weight-light: 300;
  --font-weight-bold: 500;
  --font-weight-bolder: 700;
  --font-h1: 5rem;
  --font-h1-sm: 3.6rem;
  --font-h2: 4.5rem;
  --font-h2-sm: 2.5rem;
  --font-h3: 2rem;
  --font-h3-sm: 1.4rem;
  --font-body: 1.65rem;
  --font-body-sm: 1.25rem;
  --font-h1-line-height: 6rem;
  --font-h1-sm-line-height: 4rem;
  --font-h2-line-height: 5.5rem;
  --font-h2-sm-line-height: 3rem;
  --font-h3-line-height: 3.4rem;
  --font-h3-sm-line-height: 2.8rem;
  --success: #2ecc71;
  --error: #e74c3c;
}

@media screen and (min-width: 1200px) {
  :root {
    --grid-gutter: 2rem;
  }
}
html {
  text-size-adjust: 100%;
  font-size: 100%;
  scroll-padding-top: var(--header-height);
  scroll-behavior: smooth;
}
body {
  font-family: var(--body-font);
  line-height: 1.21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  font-weight: var(--font-weight-normal);
  width: 100%;
  overflow-x: hidden;
  color: var(--text-dark);
  background-color: #faf9f6;
  margin: 0;
  padding: 0;
}

body.menuOpen {
  overflow: hidden;
}
ol,
ul {
  margin: 0 px;
}
.text-big {
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-big);
}
.text-small {
  font-size: 0.75rem;
}
.h1,
h1 {
  margin: 0 0 0.5em;
  font-size: var(--font-h1-sm);
  font-weight: 700;
  line-height: var(--font-h1-sm-line-height);
}
@media screen and (min-width: 600px) {
  .h1,
  h1 {
    margin: 0 0 0.35em;
    font-size: var(--font-h1);
    line-height: var(--font-h1-line-height);
  }
}
.h1.gutterBottom,
h1.gutterBottom {
  margin: 0 0 0.6em;
}

.h2,
h2 {
  margin: 0 0 2rem;
  line-height: var(--font-h2-sm-line-height);
  font-size: var(--font-h2-sm);
  font-weight: 700;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media screen and (min-width: 600px) {
  .h2,
  h2 {
    font-size: var(--font-h2);
    line-height: var(--font-h2-line-height);
  }
}
.h2.gutterBottom,
h2.gutterBottom {
  margin: 0 0 0.6em;
}

.h3,
h3 {
  margin: 0 0 0.35em;
  font-weight: 600;
  font-size: var(--font-h3-sm);
  line-height: 1.167;
  letter-spacing: 0;
}
@media screen and (min-width: 600px) {
  .h3,
  h3 {
    font-size: var(--font-h3);
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  color: var(--headingColor, var(--heading-dark));
}
.body1,
p {
  margin: 0 0 0.35em;
  font-weight: 400;
  font-size: var(--font-body);
  line-height: 1.3;
  letter-spacing: 0.00938em;
}
@media only screen and (max-width: 600px) {
  .body1,
  p {
    font-size: var(--font-body-sm);
    line-height: 1.4;
  }
}
p.hero {
  margin: 0 0 0.35em;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 1.3;
  letter-spacing: 0.00938em;
}
@media only screen and (max-width: 600px) {
  p.hero {
    font-size: 1.55rem;
    line-height: 1.3;
  }
}
.preamble {
  margin: 0 0 0.35em;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 1.3;
  letter-spacing: 0.00938em;
}
@media only screen and (max-width: 600px) {
  .preamble {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.body2 {
  margin: 0 px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.21;
  letter-spacing: 0.01071em;
}
small {
  font-size: 80%;
}
mark {
  background: #fcf8e3;
  padding: 0 0.3em;
}
abbr {
  border-bottom: 2px dotted #555;
  text-decoration: none;
  cursor: help;
}
kbd {
  background: var(--background-dark);
  color: var(--white);
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 80%;
}
a {
  transition: all 0.3s ease;
  cursor: pointer;
}
a,
a:hover {
  text-decoration: none;
}
em,
i {
  font-style: italic;
}
b,
strong {
  font-weight: 700;
}
hr {
  border: none;
  height: 1px;
  background: var(--border-dark);
  margin: 2em 0;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
blockquote {
  font-style: normal;
  font-size: var(--font-size-big);
  position: relative;
  font-weight: var(--font-weight-light);
  max-width: 32.5em;
  margin: 4.4em auto 4em;
  text-align: center;
}
blockquote:before {
  color: var(--theme);
  position: absolute;
  font-family: FontAwesome;
  content: "";
  top: -1.5em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  line-height: 1;
}
blockquote cite {
  display: block;
  font-size: 80%;
}
ol,
ul {
  padding-left: 20px;
}
@media only screen and (max-width: 600px) {
  ol,
  ul {
    font-size: var(--font-body-sm);
    line-height: 1.4;
  }
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}

/* .layout-block {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 4) var(--grid-gutter);
  background-color: transparent;
  color: var(--textColor);
  box-sizing: border-box;
}
.layout-block.theme-default,
.layout-block.theme-white {
  box-sizing: content-box;
} */

button {
  background-color: var(--buttonBackgroundColor) !important;
  font-family: var(--button-font) !important;
  color: var(--buttonTextColor) !important;
  font-weight: 700 !important;
  font-size: 1.6rem !important;
  padding: 1.4rem 2rem !important;
  border: none !important;
  cursor: pointer !important;
  transition: color 0.3s ease-in;
}
@media only screen and (max-width: 600px) {
  button {
    font-size: 1.4rem !important;
    padding: 1rem 1.5rem !important;
  }
}

button:hover {
  background-color: var(--buttonBackgroundColorDarker) !important;
}

section {
  position: relative;
}
section + .theme-darkGrey + .theme-darkGrey:before,
section + .theme-lightGrey + .theme-lightGrey:before,
section + .theme-rust + .theme-rust:before,
section + .theme-white + .theme-white:before,
section + .theme-yellow + .theme-yellow:before {
  border-top: 1px solid var(--dividerColor);
  content: "";
  height: 1px;
  max-width: var(--breakpoint-xl);
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  margin: auto;
  top: 0;
  left: var(--block-horisontal-padding-sm);
  right: var(--block-horisontal-padding-sm);
}

@media screen and (min-width: 900px) {
  section
    + .theme-darkGrey
    + .theme-darkGrey
    section
    + .theme-rust
    + .theme-rust:before,
  section + .theme-lightGrey + .theme-lightGrey:before,
  section + .theme-white + .theme-white:before,
  section + .theme-yellow + .theme-yellow:before {
    left: var(--block-horisontal-padding-lg);
    right: var(--block-horisontal-padding-lg);
  }
}
@media screen and (min-width: 1500px) {
  section
    + .theme-darkGrey
    + .theme-darkGrey
    section
    + .theme-rust
    + .theme-rust:before,
  section + .theme-lightGrey + .theme-lightGrey:before,
  section + .theme-white + .theme-white:before,
  section + .theme-yellow + .theme-yellow:before {
    left: 0;
    right: 0;
  }
}

.TextMediaBlock_wrapper__fd2J4 {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
}
.TextMediaBlock_content__F8JY_ {
  min-width: 100%;
  display: grid;
  position: relative;
  grid-template-areas: "media" "text";
}
.TextMediaBlock_content__F8JY_ .TextMediaBlock_text__gFT5_ {
  padding: var(--grid-gutter-lg) var(--grid-gutter);
  z-index: var(--z-content);
  position: relative;
  grid-area: text;
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor);
  overflow: hidden;
}
.TextMediaBlock_content__F8JY_ .TextMediaBlock_text__gFT5_ > * {
  flex: 1 1;
}
.TextMediaBlock_content__F8JY_ .TextMediaBlock_background__vOOsG {
  position: absolute;
  max-width: 370px;
  z-index: -1;
}
.TextMediaBlock_content__F8JY_
  .TextMediaBlock_background__vOOsG.TextMediaBlock_media-left__h0jUP {
  right: -200px;
}
.TextMediaBlock_content__F8JY_
  .TextMediaBlock_background__vOOsG.TextMediaBlock_media-right__34Pzp {
  left: -200px;
}
.TextMediaBlock_content__F8JY_ .TextMediaBlock_media__PqTtW {
  padding: var(--grid-gutter-lg) var(--grid-gutter);
  grid-area: media;
  position: relative;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--backgroundColor-textMedia);
}
.TextMediaBlock_content__F8JY_
  .TextMediaBlock_media__PqTtW.TextMediaBlock_mediaBgNoColor__txobG {
  background-color: transparent;
}
.TextMediaBlock_content__F8JY_ .TextMediaBlock_mediaInner__TNera {
  position: relative;
  width: 80%;
  height: 80%;
  min-height: 300px;
}

@media only screen and (min-width: 800px) {
  .TextMediaBlock_content__F8JY_ {
    grid-template-columns: 1.15fr 1fr;
    grid-template-areas: "text media";
  }
  .TextMediaBlock_content__F8JY_ .TextMediaBlock_text__gFT5_ {
    padding: var(--grid-gutter-md);
  }
  .TextMediaBlock_content__F8JY_.TextMediaBlock_media-left__h0jUP {
    grid-template-areas: "media text";
    grid-template-columns: 1fr 1.05fr;
  }
  .TextMediaBlock_content__F8JY_ .TextMediaBlock_media__PqTtW {
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .TextMediaBlock_content__F8JY_ .TextMediaBlock_mediaInner__TNera {
    position: relative;
    width: 70%;
    height: 70%;
  }
  .TextMediaBlock_content__F8JY_ .TextMediaBlock_background__vOOsG {
    max-width: 470px;
  }
}
@media only screen and (min-width: 1000px) {
  .TextMediaBlock_media-left__h0jUP .TextMediaBlock_text__gFT5_ {
    text-align: right;
  }
}
@media only screen and (min-width: 1500px) {
  .TextMediaBlock_content__F8JY_ .TextMediaBlock_text__gFT5_ {
    overflow: visible;
  }
}

.TextMediaBlock_media-left__34Pzp {
  display: flex;
  flex-direction: row-reverse; /* Byter plats på barnen */
  align-items: center;
  gap: 2rem;
}

.TextMediaBlock_media-right__34Pzp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
