/* === Allmän Header Stil === */
.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.header-container.scrolled {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.header-content {
  max-width: 110em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 2rem;
}

/* === Logotypen === */
.logo {
  height: 3em;
  filter: brightness(0) invert(1);
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
}

/* === Meny === */
.menu {
  display: flex;
  align-items: center;
}

.menu-items {
  display: flex;
  list-style: none;
  gap: 2.5rem;
}

.menu-item {
  position: relative;
}

.menu-item a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* === Meny Hover-effekter === */
.menu-item a:hover {
  color: #b74438;
  transform: translateY(-3px);
}

/* Underlinje Animation */
.menu-item a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #b74438;
  transition: width 0.3s ease;
}

.menu-item a:hover::after {
  width: 100%;
}

/* === Undermeny === */
.submenu {
  position: absolute;
  top: 110%;
  left: 0;
  display: none;
  opacity: 0;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 8px;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 250px;
}

.menu-item:hover > .submenu {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.submenu li {
  list-style: none;
  margin: 0.5rem 0;
}

.submenu li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu li a:hover {
  background-color: #f5f5f5;
  color: #b74438;
}

/* === Mobilmeny === */
.menu-toggle {
  display: none;
  background: none;
  background-color: none;
  --buttonBackgroundColorDarker: transparent !important;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  transition: transform 0.3s ease;
  background: transparent !important;
}

.menu-toggle:hover {
  transform: scale(1.1);
}

.menu.open {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.menu.open .menu-items {
  flex-direction: column;
  gap: 1rem;
}

.menu.open .submenu {
  position: static;
  background: none;
  box-shadow: none;
  opacity: 1;
  transform: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .menu-items {
    display: flex !important; /* Säkerställ att menyn visas på iPads */
    flex-direction: row;
    gap: 2rem;
  }

  .menu-toggle {
    display: none; /* Döljer menytogglar på större skärmar */
  }

  .submenu {
    position: absolute;
    top: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
  }
}

/* === Responsiv Design === */
@media (max-width: 1024px) {
  .logo {
    height: 1.5em;
  }
  .menu-items {
    display: none;
  }

  .menu-item a {
    font-size: 1rem;
  }

  .menu.open .menu-items {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  .submenu {
    position: static;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    opacity: 1;
    transform: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .submenu li a {
    padding: 0.75rem 1.25rem;
    background: rgba(255, 255, 255, 0.1);
  }

  .submenu li a:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 768px) {
  .header-content {
    padding: 1rem 1.5rem;
  }

  .logo {
    height: 2.5em;
  }

  .menu-toggle {
    background: rgba(0, 0, 0, 0.8);
    right: 1.5rem;
    top: 1rem;
  }
}

@media (max-width: 640px) {
  .header-content {
    padding: 1rem 1rem;
  }

  .logo {
    height: 2em;
  }

  .menu-toggle {
    right: 1rem;
    top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .menu.open .menu-items {
    flex-direction: column;
    gap: 1rem;
  }

  .submenu li a {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 360px) {
  .header-content {
    padding: 0.5rem 0.5rem;
  }

  .logo {
    height: 1.5em;
  }

  .menu-toggle {
    right: 0.5rem;
    top: 0.2rem;
  }
}

@media (max-width: 280px) {
  .header-content {
    padding: 0.2rem 0.2rem;
  }

  .logo {
    height: 1em;
  }

  .menu-toggle {
    right: 0.2rem;
    top: 0.1rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .menu-toggle {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    height: 40px !important;
    width: 40px !important;
  }
}
