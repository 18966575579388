/* Responsivitet för stora skärmar */
.large-cards-section {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 1rem;
  margin-bottom: 8em;
  max-width: 55%;
  margin: 0 auto;
}

.large-cards-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.large-card {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4rem;
  background: #fff; /* Vitt kort */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Trimma innehållet */
}

.large-card-image {
  flex: 1; /* Gör bilden mindre än kortet */
  height: auto;
  max-width: 50%; /* Begränsa bildens bredd */
}

.large-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.large-card-image:hover img {
  opacity: 0.9;
}

.large-card-content {
  flex: 2;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.large-card-content h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: bold;
}

.large-card-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.read-more-button {
  float: right;
  background: #b74438;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 1rem 2rem !important;
  font-size: 1.2rem !important;
  cursor: pointer;
  transition: background 0.3s ease;
}

.read-more-button:hover {
  transform: scale(1.05);
}

/* Responsiv justering */
@media (max-width: 1024px) {
  .large-cards-section {
    max-width: 75%;
  }
  .large-card {
    flex-direction: column; /* Ställ korten vertikalt */
    text-align: center;
  }

  .large-card-image {
    max-width: 100%; /* Bilden täcker hela bredden */
    height: 200px; /* Begränsa höjden */
  }

  .large-card-content {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .large-cards-section {
    max-width: 85%;
  }
  .large-card {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .large-card-image {
    height: 150px; /* Mindre höjd för mindre skärmar */
  }

  .large-card-content {
    padding: 1rem;
  }

  .large-card-content h2 {
    font-size: 1.5rem;
  }

  .large-card-content p {
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }

  .read-more-button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .large-cards-section {
    max-width: 85%;
  }
  .large-card-image {
    height: 120px;
  }

  .large-card-content h2 {
    font-size: 1.3rem;
  }

  .large-card-content p {
    font-size: 0.9rem;
  }

  .read-more-button {
    padding: 0.5rem 0.8rem;
    font-size: 0.85rem;
  }
}
