.td-asset-care-page {
  font-family: Arial, sans-serif;
  color: #333;
  /* background-color: #f9f9f9; */
}

.hero-section-tdasset {
  position: relative;
  height: 75vh; /* Anpassa höjden */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
    url("../assets/backgrounds/sthlm33.png") no-repeat center center/cover;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.hero-section-tdasset img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1; /* Placera bakgrundsbilden bakom texten */
}

.hero-section-tdasset-text {
  margin-top: 15vh;
  max-width: 750px;
  text-align: center;
  z-index: 2;
  padding: 1rem;
}

.hero-section-tdasset-text h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
}

.hero-section-tdasset-text p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.hero-section-tdasset-text button {
  background: #76aeb1 !important;
  color: #fff !important;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.hero-section-tdasset-text button:hover {
  background: #588a8d !important;
}

.section-container-tdasset {
  padding: 4rem 2rem;
  justify-self: center;
  max-width: 70%;
  margin: 4rem 0;
  border-radius: 12px;
}

.section-container-tdasset p {
  justify-self: center;
  max-width: 80%;
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 13vh;
}

.section-container-tdasset h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
}

.card-tdasset-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.card-tdasset {
  /* border-radius: 12px; */
  background: white;
  padding-top: 2rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-tdasset:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-tdasset img {
  max-width: 120px;
  margin-bottom: 1.5rem;
}

.card-tdasset h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: bold;
}

.card-tdasset p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
}

.description-section-tdasset {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 4rem 2rem;
  max-width: 80%;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.description-intro,
.description-features {
  padding: 1rem;
}

.description-intro h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: bold;
}

.reasons-list {
  list-style: disc;
  padding-left: 2rem;
  margin: 1.5rem 0;
}

.reasons-list li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #588a8d;
}

.description {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8;
  margin: 2rem 0;
  color: #333;
}

.description-features h3 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
  list-style-type: circle;
}

.features-list li strong {
  font-weight: bold;
  color: #000;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .description-section-tdasset {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-section-tdasset-text h1 {
    font-size: 2rem;
  }

  .hero-section-tdasset-text p {
    font-size: 1rem;
  }

  .card-tdasset-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .hero-section-tdasset {
    height: 50vh;
  }

  .hero-section-tdasset-text h1 {
    justify-self: center;
    max-width: 80%;
    font-size: 1.8rem;
  }

  .hero-section-tdasset-text p {
    max-width: 90%;
    justify-self: center;
    font-size: 1rem;
  }

  .section-container-tdasset {
    padding: 2rem 1rem;
    max-width: 80%;
  }

  .card-tdasset-grid {
    gap: 1rem;
  }

  .description-section-tdasset {
    padding: 2rem 1rem;
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .hero-section-tdasset {
    height: 70vh;
  }

  .hero-section-tdasset-text h1 {
    justify-self: center;
    max-width: 80%;
    font-size: 1.5rem;
  }

  .hero-section-tdasset-text p {
    justify-self: center;
    max-width: 95%;
    font-size: 0.8rem;
  }

  .card-tdasset-grid {
    grid-template-columns: 1fr;
  }
}
