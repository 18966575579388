.PreviewBanner_previewModeBanner__mbmON {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  padding: 1rem;
  font-weight: 700;
  text-align: center;
  background-color: var(--error);
  color: var(--text-light);
}
.PreviewBanner_previewModeBanner__mbmON a {
  color: currentColor;
  text-decoration: underline;
}

.MenuLinkList_link__GwLL_,
.MenuLinkList_link__GwLL_ a {
  display: block;
  color: #000;
}
.MenuLinkList_link__GwLL_ a:active,
.MenuLinkList_link__GwLL_ a:visited {
  color: #000;
}
.MenuLinkList_menuItemsList__VafbO {
  padding: 0;
  margin: 0;
  list-style: none;
}
.MenuLinkList_tdtest__S_O56 .MenuLinkList_menuItemsList__VafbO {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
}
.MenuLinkList_linkOpen__QlRS_ .MenuLinkList_submenu__33dqI {
  display: block;
}
.MenuLinkList_linkOpen__QlRS_ .MenuLinkList_dropDown__RsJLd svg {
  transform: rotate(90deg);
}
.MenuLinkList_label__xGX_V {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.MenuLinkList_label__xGX_V:hover .MenuLinkList_labelText__zdOEm {
  transform: translateX(5px);
  transition: transform 0.1s linear;
}
.MenuLinkList_labelText__zdOEm {
  display: block;
  padding: calc(var(--grid-gutter) / 2) var(--grid-gutter);
  flex: 1 1;
  cursor: pointer;
}
.MenuLinkList_dropDown__RsJLd {
  display: flex;
  align-items: center;
  padding: 0 var(--grid-gutter);
  cursor: pointer;
}
.MenuLinkList_dropDown__RsJLd svg {
  transform: rotate(0);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.MenuLinkList_submenu__33dqI {
  margin: 0;
  display: none;
  padding: 0;
  list-style: none;
  font-size: var(--font-size);
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
@media screen and (min-width: 1000px) {
  .MenuLinkList_menuItemsList__VafbO {
    display: flex;
  }
  .MenuLinkList_link__GwLL_ {
    margin-left: calc(var(--grid-gutter) / 2);
  }
  .MenuLinkList_link__GwLL_:hover .MenuLinkList_labelTextTop__rVjds {
    text-decoration: underline;
    transform: none;
  }
  .MenuLinkList_link__GwLL_:hover .MenuLinkList_submenu__33dqI {
    display: block;
  }
  .MenuLinkList_dropDown__RsJLd {
    padding: 0 calc(var(--grid-gutter) / 2) 0 0;
  }
  .MenuLinkList_dropDown__RsJLd.MenuLinkList_dropDownHidden___knyd {
    display: none;
  }
  .MenuLinkList_dropDown__RsJLd svg {
    transform: rotate(90deg);
    transition: none;
  }
  .MenuLinkList_labelText__zdOEm {
    padding: calc(var(--grid-gutter) / 2);
  }
  .MenuLinkList_submenu__33dqI {
    position: absolute;
    opacity: 1;
    top: 100%;
    box-shadow: 0 3px 6px rgba(57, 63, 72, 0.3);
    background: #fff;
    min-width: 200px;
  }
  .MenuLinkList_submenuRight__RVZ_z {
    right: 0;
  }
}
.Menu_menu__PioKF {
  display: block;
  margin: 0;
  padding: 0;
  grid-area: right;
  font-size: var(--font-size-big);
}
.Menu_links__n5GVG {
  position: absolute;
  top: var(--header-height-mobile);
  left: 0;
  width: 100vw;
  background: #fff;
  box-shadow: inset 0 3px 5px rgba(57, 63, 72, 0.1),
    0 3px 5px rgba(57, 63, 72, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Menu_menuVisible__GPNEk .Menu_links__n5GVG {
  opacity: 1;
  pointer-events: all;
}
@media only screen and (min-width: 1000px) {
  .Menu_menu__PioKF {
    top: 0;
    position: relative;
  }
  .Menu_hamburgerMenuLink__qMRhe {
    display: none;
  }
  .Menu_links__n5GVG {
    pointer-events: all;
    top: 0;
    position: relative;
    width: auto;
    box-shadow: none;
    gap: var(--grid-gutter);
    opacity: 1;
    flex-direction: row;
  }
  .Menu_menu__PioKF:after {
    top: var(--header-height);
  }
}
.Header_wrapper__a0rpx {
  display: grid;
  grid-template-areas: "left . right";
  grid-template: min-content 1fr min-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.Header_logoWrapper__wbaCP {
  grid-area: left;
}
.Header_logoLink__T17p7 {
  display: block;
  max-height: 100%;
  min-width: 120px;
}
@media only screen and (min-width: 1000px) {
  .Header_logoLink__T17p7 {
    display: block;
    max-height: 100%;
    min-width: 160px;
  }
}

.HeaderContainer_themePadding__xcIxb {
  border-style: none;
}
.HeaderContainer_outer__X0Ed3 {
  background-color: var(--backgroundColor);
  color: var(--textColor);
  position: relative;
  height: var(--header-height-mobile);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--z-header);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);
}
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 div,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 ul {
  background-color: #1d1c20;
  color: #fff;
}
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 a,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 a:active,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 a:visited,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 li,
.HeaderContainer_outer__X0Ed3.HeaderContainer_tdtest__NPLG2 span {
  color: #fff;
}
.HeaderContainer_boxed__HcUVH {
  height: 100%;
  max-width: var(--breakpoint-xl);
  padding: 0 var(--grid-gutter);
  margin-left: auto;
  margin-right: auto;
}
.HeaderContainer_width-full__CFv_m {
  padding-left: 0;
  padding-right: 0;
}
.HeaderContainer_width-small__XGWWY {
  max-width: var(--breakpoint-sm);
}
.HeaderContainer_width-medium__upgdJ {
  max-width: var(--breakpoint-md);
}
.HeaderContainer_width-large__PPvWU {
  max-width: var(--breakpoint-xl);
}
@media only screen and (min-width: 1000px) {
  .HeaderContainer_outer__X0Ed3 {
    height: var(--header-height);
  }
  .HeaderContainer_width-full__CFv_m {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .HeaderContainer_width-small__XGWWY.HeaderContainer_themePadding__xcIxb {
    max-width: var(--block-horisontal-maxwidth-sm-minus);
  }
  .HeaderContainer_width-medium__upgdJ.HeaderContainer_themePadding__xcIxb {
    max-width: var(--block-horisontal-maxwidth-md-minus);
  }
  .HeaderContainer_width-large__PPvWU.HeaderContainer_themePadding__xcIxb {
    max-width: var(--block-horisontal-maxwidth-lg-minus);
  }
}
