/* === Hero Sektion === */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 10em;
}

/* === Video === */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #588a8d, #ffffff);
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.video-background.active {
  opacity: 1;
}

/* === Overlay === */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
  pointer-events: none;
}

/* === Overlay Content === */
.overlay-content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  padding: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  width: 50%;
  max-width: 700px;
  text-align: center;
  z-index: 2;
}

.overlay-content h1 {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.overlay-content p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

/* === Responsiv Design === */
/* Stora skärmar */
@media (min-width: 1200px) {
  .overlay-content {
    padding: 4rem;
    width: 50%;
  }

  .overlay-content h1 {
    font-size: 3rem;
  }

  .overlay-content p {
    font-size: 1.2rem;
  }
}

/* Mellanstora skärmar */
@media (max-width: 1024px) {
  .overlay-content {
    width: 70%;
    padding: 3rem;
  }

  .overlay-content h1 {
    font-size: 2.4rem;
  }

  .overlay-content p {
    font-size: 1rem;
  }
}

/* Små skärmar (mobiler och mindre tablets) */
@media (max-width: 768px) {
  .overlay-content {
    width: 75%;
    padding: 1rem;
  }

  .overlay-content h1 {
    font-size: 1.8rem;
  }

  .overlay-content p {
    font-size: 0.9rem;
  }
}

/* Väldigt små skärmar (stående mobiler) */
@media (max-width: 480px) {
  .overlay-content {
    width: 90%;
    padding: 1.5rem;
  }

  .overlay-content h1 {
    font-size: 1.8rem;
  }

  .overlay-content p {
    font-size: 0.8rem;
  }
}

/* Extra små skärmar */
@media (max-width: 320px) {
  .overlay-content {
    padding: 1rem;
  }

  .overlay-content h1 {
    font-size: 1.6rem;
  }

  .overlay-content p {
    font-size: 0.7rem;
  }
}
