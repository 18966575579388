@import url(https://fonts.bunny.net/css?family=ibm-plex-sans:400,600);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap);

.layout-block {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 4) var(--grid-gutter);
  background-color: transparent;
  color: var(--textColor);
  box-sizing: border-box;
}

p.hero {
  margin: 0 0 0.35em;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: 0.00938em;
}

.HeroCard_wrapper {
  margin-top: 80px;
  position: relative;
  display: grid;
  grid-template-areas: "header" "copy";
}
.HeroCard_wrapper.HeroCard_textCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.HeroCard_headingText {
  position: relative;
  grid-area: header;
  max-width: 19ch;
}
.HeroCard_copy {
  position: relative;
  grid-area: copy;
}
@media only screen and (min-width: 1100px) {
  .HeroCard_wrapper {
    gap: 0 var(--grid-gutter-lg);
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1.5fr minmax(200px, 0.7fr);
    grid-template-areas: "header media" "copy media";
  }
  .HeroCard_wrapper.HeroCard_textCentered {
    padding: 0 14rem;
  }
  .HeroCard_copy {
    max-width: 100ch;
  }
}

.new-hero-heading {
  position: relative;
  color: white; /* Anpassa färgen så att den passar din bild */
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8); /* Lägger till en mjuk skugga runt texten */
}

.new-hero-card {
  position: relative;
  color: white;
}

.new-hero-heading,
.new-hero-card {
  position: relative;
  color: white;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  border-radius: 4px; /* lägg till detta */
}

#Hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  ); */
  pointer-events: none;
}

.TextMediaBlock_mediaInner-content {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Pilar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

.TextMediaBlock_wrapper {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
}
.TextMediaBlock_content {
  min-width: 100%;
  display: grid;
  position: relative;
  grid-template-areas: "media" "text";
}
.TextMediaBlock_content .TextMediaBlock_text {
  padding: var(--grid-gutter-lg) var(--grid-gutter);
  z-index: var(--z-content);
  position: relative;
  grid-area: text;
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor);
  overflow: hidden;
}
.TextMediaBlock_content .TextMediaBlock_text > * {
  flex: 1 1;
}

.TextMediaBlock_content .TextMediaBlock_media {
  padding: var(--grid-gutter-lg) var(--grid-gutter);
  grid-area: media;
  position: relative;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--backgroundColor-textMedia);
}

.TextMediaBlock_content .TextMediaBlock_mediaInner {
  position: relative;
  width: 80%;
  height: 80%;
  min-height: 300px;
}
.TextMediaBlock_tdtest .TextMediaBlock_content {
  min-height: 600px;
}
@media only screen and (min-width: 800px) {
  .TextMediaBlock_content {
    grid-template-columns: 1.15fr 1fr;
    grid-template-areas: "text media";
  }
  .TextMediaBlock_content .TextMediaBlock_text {
    padding: var(--grid-gutter-md);
  }

  .TextMediaBlock_content .TextMediaBlock_media {
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .TextMediaBlock_content .TextMediaBlock_mediaInner {
    position: relative;
    width: 70%;
    height: 50%;
  }
}

@media only screen and (min-width: 1500px) {
  .TextMediaBlock_content .TextMediaBlock_text {
    overflow: visible;
  }
}

.PromoTable_greencheck {
  color: #217321;
}
.PromoTable_nocheck {
  color: var(--textColor-muted);
}
.PromoTable_promotableContainer {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  color: var(--textColor);
}
@media only screen and (max-width: 900px) {
  .PromoTable_promotableContainer {
    overflow-x: scroll;
  }
}
.PromoTable_promotableTitles {
  padding: 1rem;
}
.PromoTable_promotableHeader {
  display: flex;
  flex-direction: row;
}
.PromoTable_promotableHeader div {
  display: flex;
  align-items: center;
  flex: 1 1;
  text-align: center;
  padding: 1rem;
  min-width: 80px;
}
.PromoTable_promotableHeader div p {
  font-size: var(--font-size-small);
}
.PromoTable_promotableHeader div:first-child {
  text-align: left;
  font-weight: 700;
  font-size: var(--font-size-big);
  flex: 2 1;
  min-width: 240px;
}
.PromoTable_promotableHeader div:not(:first-child) {
  justify-content: center;
}
.PromoTable_promotableRows {
  display: flex;
  flex-direction: column;
}
.PromoTable_promoTableRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  max-height: 80px;
  border-top: 0.5px solid var(--cardBorderColor);
}
.PromoTable_promoTableRow div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  text-align: center;
  padding: 1rem;
  min-width: 80px;
  min-height: 70px;
}
.PromoTable_promoTableRow div:first-child {
  text-align: left;
  font-weight: 700;
  flex: 2 1;
  min-width: 240px;
}
.PromoTable_promoTableRow div p {
  text-align: left;
  width: 100%;
  font-size: var(--font-size-small);
}

.Card_wrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--cardBackgroundColor);
  border: 0.5px solid var(--cardBorderColor);
  border-radius: 17px;
  padding: 1rem;
}
.Card_iconContainer {
  margin-bottom: 1rem;
  margin-right: 0.75rem;
}
.Card_iconContainer .Card_icon-small {
  height: 29px;
  width: 29px;
}

.Card_heading {
  margin-bottom: 1rem;
}
.Card_heading h3 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.Card_text {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Card_text li,
.Card_text ul,
.Card_text ul p {
  font-size: var(--font-body-sm);
  line-height: 1.8;
}
.Card_text h1 {
  font-size: 2.5rem;
  margin: 0 0 0.25em;
  line-height: normal;
}
.Card_text h2 {
  font-size: 2rem;
  margin: 0 0 0.5em;
  line-height: normal;
}
.Card_text h3 {
  font-size: 1.75rem;
  margin: 0 0 0.5em;
  line-height: normal;
}
.Card_text h4 {
  font-size: 1.5rem;
  margin: 0 0 1em;
  line-height: normal;
}
.Card_text h5 {
  font-size: 1.25rem;
  margin: 0 0 1em;
  line-height: normal;
}
.Card_text h6 {
  font-size: 1rem;
  margin: 0 0 1em;
  line-height: normal;
}

.Card_image {
  margin-top: 2rem;
  max-width: 100%;
  border-radius: 17px;
  overflow: hidden;
}
.Card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Card_image img:hover {
  cursor: pointer;
}
@media only screen and (min-width: 340px) {
  .Card_wrapper {
    padding: 2rem;
  }
}
@media only screen and (min-width: 700px) {
  .Card_wrapper {
    padding-bottom: 3rem;
  }

  .Card_iconContainer {
    margin-bottom: 0;
  }
}
.CardBlock_content {
  position: relative;
  box-sizing: border-box;
  max-width: var(--breakpoint-xl);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  justify-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  grid-template-areas: "intro" "partnerList";
}
.CardBlock_intro {
  grid-area: intro;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 90ch;
  text-align: center;
}
.CardBlock_cardList {
  grid-area: partnerList;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}
@media only screen and (min-width: 700px) {
  .CardBlock_content {
    padding-left: var(--block-horisontal-padding-md);
    padding-right: var(--block-horisontal-padding-md);
  }
}
@media only screen and (min-width: 1100px) {
  .CardBlock_cardList {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 70%;
  max-height: 70%;
}

.modal-content img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

/* Close Button */
.modal-close-button {
  background-color: transparent !important;
  --buttonBackgroundColorDarker: #878787 !important;
  padding: 0.5rem 1rem !important;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-close-button:hover .close-icon {
  fill: #fff;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0.65;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 0.35;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInStrong {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutStrong {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Flex Centering */
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tdtest {
  background-image: url("../assets/background_tdtest.png");
  background-color: #0e0e11;
  /* background-size: cover;
  background-repeat: no-repeat; */
  background-position: center;
}

body.tdtest {
  color: var(--text-light);
}

.tdtest .h1,
.tdtest h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: normal;
}
@media screen and (min-width: 700px) {
  .tdtest .h1,
  .tdtest h1 {
    font-size: 4rem;
  }
}
.tdtest .h2,
.tdtest h2 {
  font-weight: 600;
  font-size: 3rem;
}
@media screen and (min-width: 700px) {
  .tdtest .h2,
  .tdtest h2 {
    font-size: 4rem;
  }
}
.tdtest button {
  border-radius: 16px;
}
.tdtest section + .theme-darkGrey + .theme-darkGrey:before,
.tdtest section + .theme-lightGrey + .theme-lightGrey:before,
.tdtest section + .theme-rust + .theme-rust:before,
.tdtest section + .theme-white + .theme-white:before,
.tdtest section + .theme-yellow + .theme-yellow:before {
  border-top: none;
}
.tdtest footer,
.tdtest footer div {
  background-color: #1d1c20;
  color: #fff;
}
.tdtest footer a,
.tdtest footer a:active,
.tdtest footer a:visited,
.tdtest footer span {
  color: #fff;
}
.TextMediaBlock_tdtest .TextMediaBlock_content {
  min-height: 600px;
}
.theme-tdtest {
  --backgroundColor: transparent;
  --backgroundColor-textMedia: transparent;
  --headingColor: #fff;
  --textColor: #fff;
  --textColor-muted: #d9d9d9;
  --buttonBackgroundColor: #a32700;
  --buttonBackgroundColorDarker: #871f00;
  --buttonTextColor: #fff;
  --dividerColor: #fff;
  --theme-horizontal-padding: var(--block-horisontal-padding-lg);
  --cardBackgroundColor: #1d1c20;
  --cardBorderColor: #2f2e31;
  --promoTableHighlight: #1d1c20;
}
