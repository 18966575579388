.services-section {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 10em;
}

.services-section h2 {
  font-size: 3rem;
  margin-bottom: 30px;
  color: #333;
}

.services-cards-wrapper {
  justify-self: center;
  max-width: 100em;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.services-card {
  position: relative;
  width: 300px;
  border-radius: 0px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Lägger till övergång för bakgrundsfärg */
}

.services-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.services-card-image img {
  width: 50%;
  height: 50%;
  object-fit: cover;
  margin-top: 2em;
}

/* .services-card-image:hover img {
  transform: scale(1.05);
} */

.services-card-content {
  padding: 15px;
  /* background: rgba(255, 255, 255, 0.8); */
}

.services-card-content h3 {
  font-size: 1.4rem;
  color: #333;
  margin: 0 0 10px;
}

.services-card-content p {
  font-size: 1rem;
  color: #777;
  margin: 0 0 20px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.services-card-arrow {
  font-size: 1.8rem;
  color: #588a8d;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.7s ease-in-out;
}

.services-card-arrow:hover {
  transform: translateX(10px);
}

@media (max-width: 1024px) {
  .services-section h2 {
    font-size: 2.5rem;
    margin-bottom: 5rem;
    color: #333;
  }
}

@media (max-width: 768px) {
  .services-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .services-card {
    width: 90%;
  }

  .services-card-content h3 {
    font-size: 1.2rem;
  }

  .services-card-content p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .services-card {
    width: 90%;
  }

  .services-card-content h3 {
    font-size: 1.4rem;
  }

  .services-card-content p {
    font-size: 0.9rem;
  }
}
